import React, { useContext, useEffect, useState } from "react";
import ProgressPercentage from "../ProgressPercentage";
import { LocationIcon, CheckCircleIcon } from "../../icons";
import { CssTooltip } from "../StyledComponent";
import { BookmarkIcon } from "../../icons";
import { useTranslation } from "react-i18next";
import { calculatePostedDays } from "../../common/common";
import { jobStatus, templates } from "../../common/constants";
import { saveJob } from "../../lib/jobs-api";
import { useMutation, useQueryClient } from "react-query";
import { AuthContext } from "../../context/AuthContext";
import ErrorDialog from "../Dialog/ErrorDialog";

function JobListingDetails({
  company,
  key,
  onClickDetail,
  isContainerShrunk,
  setSelectedJob,
  refetch,
  isOpenSearch,
}) {
  const { t } = useTranslation();
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const queryClient = useQueryClient();
  const [save, setSaved] = useState(company?.is_saved);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [message, setMessage] = useState("");
  const { mutate: saveJobApi } = useMutation(saveJob);
  const { userId, template } = useContext(AuthContext);
  const handleDetailClick = (company) => {
    onClickDetail && onClickDetail();
    setSelectedJob(company); // Pass the selected job details to the parent component
  };

  const handleErrorDialogOpen = () => {
    setErrorDialogOpen(true);
  };

  const handleErrorDialogClose = () => {
    setErrorDialogOpen(false);
  };

  useEffect(() => {
    if (company?.is_saved !== save) {
      setTimeout(() => {
        setSaved(company.is_saved);
      }, 1000);
    }
  }, [company?.is_saved, save]);

  return (
    <div>
      <div
        //key={key}
        className={` ${hoveredIndex === key ? "bg-blue-100" : ""}`}
        onMouseEnter={() => setHoveredIndex(key)}
        onMouseLeave={() => setHoveredIndex(-1)}
        onClick={() => handleDetailClick(company)} // Pass the job details as a parameter
      >
        <div className="flex flex-grow w-full gap-5  p-5 border-b border-b-borderCompany">
          <img
            src={company?.company_logo}
            alt="Company Logo"
            className=" h-[50px] w-[50px] "
          />

          <div className={`w-[40%] ${isContainerShrunk ? "w-[50%]" : ""}`}>
            <div className="text-secondary text-sm"> {company?.job_title}</div>
            <div className="text-title text-xs font-medium"> {company?.company}</div>
            <div className="text-grayTextColor text-xs font-medium">
              {/* <div className="text-tertiary text-xs font-medium"> */}
              {company?.company_website}
            </div>
            <div
              className={`text-title text-xs font-medium   line-clamp-1 ${
                isContainerShrunk ? "sm:flex" : "flex sm:hidden"
              }`}
            >
              {company?.city[0]}
            </div>
            <div
              className={`my-auto whitespace-nowrap pt-3 ${
                isContainerShrunk ? "sm:flex" : "flex sm:hidden"
              }`}
            >
              <div className="text-grayTextColor text-sm -ml-5 md:-ml-0">
                {/* <div className="text-tertiary text-sm -ml-5 md:-ml-0"> */}
                {company?.applied_date === null ?<div>
                  { ( calculatePostedDays(company?.posted_date) < 30 )? <div> {calculatePostedDays(company?.posted_date) + " " + t("_days_ago")}</div> : <div>{t("_30_days_ago")}</div>}
                </div>: (
                  <div className="inline-flex gap-2">
                    <CheckCircleIcon />{" "}
                    <p className="text-successColor">
                      {calculatePostedDays(company?.applied_date) !== 1
                        ? calculatePostedDays(company?.applied_date) +
                          " " +
                          t("_days_ago")
                        : calculatePostedDays(company?.applied_date) +
                          " " +
                          t("_day_ago")}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            className={`my-auto w-[20%]  gap-2 items-center pt-3  hidden  ${
              isContainerShrunk ? "hidden" : "sm:flex"
            } `}
          >
            <LocationIcon />
            <div className="text-title text-sm font-medium ">{company?.city[0]}</div>
          </div>
          <div className="w-[10%] mb-auto pt-3 ">
            {company?.score ? (
              <div className="flex items-center ">
                <CssTooltip
                  title={
                    isOpenSearch
                      ? t("_Search_vs_JD")
                      : t("_Percentage_of_profile_Vs_Job_match")
                  }
                  placement="top"
                  arrow
                >
                  <span>
                    <ProgressPercentage
                      variant="determinate"
                      value={company?.score * 100}
                      sx={{ scale: "-1 -1" }}
                    />
                  </span>
                </CssTooltip>
              </div>
            ) : null}
          </div>
          <div
            className={`w-[20%]  my-auto whitespace-nowrap pt-3 md:pt-0 ${
              isContainerShrunk ? "hidden" : "hidden sm:flex"
            }`}
          >
            <div className="text-grayTextColor text-sm -ml-5 md:-ml-0">
              {/* <div className="text-tertiary text-sm -ml-5 md:-ml-0"> */}
               {company?.applied_date === null ? (
                <div>
                  { ( calculatePostedDays(company?.posted_date) < 30 )? <div> {calculatePostedDays(company?.posted_date)>1?calculatePostedDays(company?.posted_date) + " " + t("_days_ago"):calculatePostedDays(company?.posted_date)=== 0? t("_Today"):calculatePostedDays(company?.posted_date) + " " + t("_day_ago")}</div> : <div>{t("_30_days_ago")}</div>}
                </div>
              ) : (
                <div className="inline-flex gap-2">
                  <CheckCircleIcon />{" "}
                  <p className="text-successColor">
                    {calculatePostedDays(company?.applied_date) + " " + t("_days_ago")}
                  </p>
                </div>
              )}
            </div>
          </div>
          {template !== templates.platformAdmin ? (
            <div className="w-[5%] my-auto pt-3 md:pt-0 flex justify-end flex-auto responsive-bookmark-cls">
              <BookmarkIcon
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  //setSaved(1);

                  saveJobApi(
                    {
                      userId,
                      job_id: company?.job_id,
                      mark: save === 1 ? 0 : 1,
                      score: isOpenSearch ? 0 : company?.score,
                    },

                    {
                      onSuccess: () => {
                        refetch();
                        queryClient.invalidateQueries(`JobDeatils/${company?.job_id}`);
                      },
                      onError: (error) => {
                        handleErrorDialogOpen();
                        setMessage(error?.response?.data?.message);
                        refetch();
                      },
                    }
                  );
                }}
                className={`${
                  save === jobStatus.saved ? "text-[#0071b6]" : "text-white"
                }`}
              />
            </div>
          ) : null}
        </div>
      </div>
      <ErrorDialog
        open={errorDialogOpen}
        message={message}
        onClick={handleErrorDialogClose}
      />
    </div>
  );
}

export default JobListingDetails;
