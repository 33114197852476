/**
 * ⚠ These are used just to render the Sidebar!
 * You can include any link here, local or external.
 *
 * If you're looking to actual Router routes, go to
 * `routes/index.js`
 */

///Candidate Left menu
const routes = [
  {
    path: "/app/dashboard",
    icon: "DashboardIcon",
    name: "_Dashboard",
  },
  {
    path: "/app/jobs/recommended",
    icon: "JobsIcon",
    name: "_Jobs",
  },
  {
    path: "/app/interviews/schedule-interview",
    icon: "InterviewsIcon",
    name: "_Interviews",
  },
  {
    path: "/app/assessments",
    icon: "AssessmentsIcon",
    name: "_Assessments",
  },
  {
    path: "/app/upgrade-skills",
    icon: "UpgradeSkillsIcon",
    name: "_Upgrade_skills",
  },
  {
    path: "/app/admin/whats-new",
    icon: "GiftIcon",
    name: "_what's_new",
  },
  {
    path: "/app/resume-tools",
    icon: "ResumeToolsIcon",
    name: "_Resume_tools",
  },
  {
    path: "/app/career-tools",
    icon: "CareerToolsIcon",
    name: "_Career_tools",
  },
  {
    path: "/app/mentoring",
    icon: "MentoringIcon",
    name: "_Mentoring",
  },
  {
    path:"/app/mock-interview",
    icon: "MockInterviewIcon",
    name: "_Mock_Interview",
  }
];
//Admin left menu
// const routes = [
//   {
//     path: "/app/dashboard",
//     icon: "DashboardIcon",
//     name: "_Dashboard",
//   },
//   {
//     path: "/app/admin/candidate/support",
//     icon: "CandidateSupportIcon",
//     name: "_Candidate_support",
//   },
//   {
//     path: "/app/admin/recruiter/support",
//     icon: "RecruiterSupportIcon",
//     name: "_Recruiter_support",
//   },
//   {
//     path: "/app/admin/sales",
//     icon: "SalesTicketIcon",
//     name: "_Sales_Tickets",
//   },
//   {
//     path: "/app/admin/accounts",
//     icon: "AccountIcon",
//     name: "_Account_management",
//   },
//   {
//     path: "/app/admin/customer/create",
//     icon: "CreateCustomerIcon",
//     name: "_Create_Customer",
//   },
//   {
//     path: "/app/admin/job/create",
//     icon: "PostAJobIcon",
//     name: "_Post_a_job",
//   },
//   {
//     path: "/app/admin/candidate/search",
//     icon: "CandidateSearchIcon",
//     name: "_Candidate_profile_search",
//   },
//   {
//     path: "/app/admin/alerts",
//     icon: "AlertsIcon",
//     name: "_Alerts",
//   },
//   {
//     path: "/app/admin/reports",
//     icon: "ReportsIcon",
//     name: "_Reports",
//   },
// ];

export default routes;
