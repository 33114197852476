import { axiosPrivate } from "../common/axiosPrivate";
import getAPIMap from "../routes/ApiUrls";

/**
 * This method is used to get Candidate Events
 */
export async function getCandidateEvents(options) {
  let url = getAPIMap("getCandidateEvents");
  url = `${url}?page=${options.queryKey[1]}&limit=${options.queryKey[2]}`;
  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used to get Event By Id
 */
export async function getCandidateEventById(options) {
  let url = getAPIMap("getCandidateEventById");
  url = url.replace("{eventId}", options.queryKey[1]);

  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used to register Event
 */
export async function registerEvent(options) {
  let url = getAPIMap("registerEvent");
  url = url.replace("{eventId}", options.eventId);

  let response = axiosPrivate.post(url, options.payload);
  return response;
}
