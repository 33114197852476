import React from "react";
import AuthTemplate from "../containers/AuthTempalte";
import UploadResumeForm from "../components/Forms/UploadResumeForm";
import SignUpImage from "../assets/img/candidateimage.jpg";
// import CuratalLogo from "../assets/img/logo.jpg";
import CuratalLogo from "../assets/img/Curatal-new-logo.JPG";

import { useTranslation } from "react-i18next";
import CheckmarkIcon from "../assets/img/CheckmarkBulletIcon.png";
import MascotIcon from "../assets/img/mascot.png";

const ExistingUserUploadResume = () => {
  const { t } = useTranslation();

  const LeftComponent = () => {
    return (
      <div className="bg-gradient-to-t from-[#0071B6] to-[#00B2E9] from-10% w-[600px] h-[620px] p-9 pb-0">
        <p className="text-white text-3xl  font-extrabold mt-5">
          {t("_Access._Discover._Develop")}
        </p>
        <p className="text-white mt-2">
          {t("_Get_Assessed._Get_Discovered._Develop_Career")}
        </p>
        <p className="text-white mt-2">{t("_It_All_A.D.Ds_Up_on_Curatal")}</p>
        <p className="text-white text-[25px] mt-4 font-bold">
          {t("_Ready_for_a_thrilling_adventure_with_Curatal?")}
        </p>
        <div className="flex mt-10 gap-5">
          <img src={CheckmarkIcon} alt="checkmark" className="h-8 w-8 -ml-2" />
          <p className="text-white text-base mt-1">
            {t("_One-click_apply_with_your_Curatal_profile")}
          </p>
        </div>
        <div className="flex mt-7 gap-5">
          <img src={CheckmarkIcon} alt="checkmark" className="h-8 w-8 -ml-2" />
          <p className="text-white text-base mt-1">
            {t("_Receive_tailored_job_recommendations")}
          </p>
        </div>
        <div className="flex mt-7 gap-5">
          <img src={CheckmarkIcon} alt="checkmark" className="h-8 w-8 -ml-2" />
          <p className="text-white text-base w-60 mt-1">
            {t("_Let_the_right_jobs_find_you")}
          </p>
        </div>
        <div className="flex mt-7 gap-5">
          <img src={CheckmarkIcon} alt="checkmark" className="h-8 w-8 -ml-2" />
          <p className="text-white text-base  mt-1">
            {t("_Get_updates_on_your_application_status")}
          </p>
        </div>
        <div className="flex mt-5 gap-5">
          <img src={CheckmarkIcon} alt="checkmark" className="h-8 w-8 -ml-2" />
          <p className="text-white text-base mt-1">
            {t("_Get_hired_and_develop_your_career")}
          </p>
        </div>
      </div>
    );
  };
  return (
    <div className="px-1 sm:px-10">
      <AuthTemplate
        // leftPanelIcon={SignUpImage}
        // containerClassName={"min-h-screen"}
        // IconClassname={"w-[600px] h-[620px] "}
        LeftComponent={LeftComponent}
        // panelClassName={
        //   "bg-gradient-to-r from-[#0071B6] to-[#AFE0FF] from-10% rounded-none"
        // }
      >
        <main className="relative flex flex-col items-center lg:px-16 lg:border rounded-r-lg overflow-hidden w-full lg:w-1/2  ">
          <div className="lg:absolute mb-8 lg:mb-0 top-3 right-3 w-full flex justify-end ">
            <img src={CuratalLogo} className=" h-[44px]" alt="logo" />
          </div>
          <div className="w-full my-auto max-w-[500px]">
            <UploadResumeForm />
          </div>
        </main>
      </AuthTemplate>
    </div>
  );
};

export default ExistingUserUploadResume;
