import * as Yup from "yup";
import { t } from "i18next";
import dayjs from "dayjs";
import {
  commonRegex,
  specialRegex,
  onlySpaceRegex,
  passwordRegex,
  alphanumericRegex,
  linkedinRegex,
  websiteRegex,
  passwordRestrictedRegex,
  passwordAllowedCharacters,
  onlyZerosRegex,
  onlyZerosNotAllowedRegex,
  pincodeVaidation,
  invalidPINRegex,
  onlyNumSpaceNotAllowed,
} from "./constants";
import { State } from "country-state-city";

const mobileNumberValidation = Yup.string().test((val, ctx) => {
  if (
    val?.replace(ctx?.parent?.mobileNumberdialCode, "") === "" ||
    val === "" ||
    val === undefined
  ) {
    return ctx.createError({
      path: ctx.path,
      message: t("_Mobile_number_is_required"),
    });
  } else if (val?.replace(ctx?.parent?.mobileNumberdialCode, "")[0] === "0") {
    return ctx.createError({
      path: ctx.path,
      message: t("_Mobile_number_cannot_start_with_0"),
    });
  } else if (
    ctx?.parent?.mobileNumberformat?.length !==
    ctx?.parent?.mobileNumberformattedValue?.length
  ) {
    return ctx.createError({
      path: ctx.path,
      message: t("_Enter_valid_mobile_number"),
    });
  } else {
    return true;
  }
});

const altMobileNumberValidation = Yup.string().test((val, ctx) => {
  if (val?.replace(ctx?.parent?.altMobileNumberdialCode, "")[0] === "0") {
    return ctx.createError({
      path: ctx.path,
      message: t("_Mobile_number_cannot_start_with_0"),
    });
  } else if (
    ctx.parent?.altMobileNumberformat?.length !==
      ctx.parent?.altMobileNumberformattedValue?.length &&
    ctx?.parent?.altMobileNumberdialCode !== val
  ) {
    return ctx.createError({
      path: ctx.path,
      message: t("_Enter_valid_mobile_number"),
    });
  } else {
    return true;
  }
});

const altMobileNumberValidationRequired = Yup.string().test((val, ctx) => {
  if (val?.replace(ctx?.parent?.altMobileNumberdialCode, "") === "" || val === "") {
    return ctx.createError({
      path: ctx.path,
      message: t("_Contact_number_is_required"),
    });
  } else if (val?.replace(ctx?.parent?.altMobileNumberdialCode, "")[0] === "0") {
    return ctx.createError({
      path: ctx.path,
      message: t("_Mobile_number_cannot_start_with_0"),
    });
  } else if (
    ctx.parent?.altMobileNumberformat?.length !==
      ctx.parent?.altMobileNumberformattedValue?.length &&
    ctx?.parent?.altMobileNumberdialCode !== val
  ) {
    return ctx.createError({
      path: ctx.path,
      message: t("_Enter_valid_mobile_number"),
    });
  } else {
    return true;
  }
});

// const mobileNumberValidation = Yup.string().test((value, ctx) => {
//   if (ctx.parent?.mobileNumber?.replace(ctx?.parent?.dialCode, "")[0] === "0") {
//     return ctx.createError({
//       path: `${ctx.path}`,
//       message: t("_Mobile_number_cannot_start_with_0"),
//     });
//   }

//   if (
//     ctx.parent?.mobileNumber?.replace(ctx?.parent?.dialCode, "") === "" ||
//     value === ""
//   ) {
//     return ctx.createError({
//       path: `${ctx.path}`,
//       message: t("_Mobile_number_is_required"),
//     });
//   }
//   const formattedValueWithoutFormat = ctx.parent?.formattedValue?.replace(/\D/g, "");
//   const mobileNumberWithoutFormat = ctx.parent?.mobileNumber?.replace(/\D/g, "");

//   if (formattedValueWithoutFormat === mobileNumberWithoutFormat) {
//     if (ctx.parent?.format?.length !== ctx.parent?.formattedValue?.length) {
//       // matching the pattern
//       const length = (
//         ctx.parent?.format?.substring(ctx.parent?.format.indexOf(" ") + 1).match(/\./g) ||
//         []
//       ).length;
//       return ctx.createError({
//         path: `${ctx.path}`,
//         message: t("_Mobile_number_must_be_of_length") + " " + length,
//       });
//     }
//   }

//   let numberData = ctx.parent?.mobileNumber?.replace(ctx?.parent?.dialCode, "");
//   if (numberData < 1) {
//     return ctx.createError({
//       path: `${ctx.path}`,
//       message: t("_Enter_valid_mobile_number"),
//     });
//   }
//   return true;
// });

// const altMobileNumberValidation = Yup.string().test((value, ctx) => {
//   if (ctx.parent?.altMobileNumber?.replace(ctx?.parent?.dialCode, "")[0] === "0") {
//     return ctx.createError({
//       path: `${ctx.path}`,
//       message: t("_Mobile_number_cannot_start_with_0"),
//     });
//   }
// const formattedValueWithoutFormat = ctx.parent?.formattedValue.replace(/\D/g, "");
// const altMobileNumberWithoutFormat = ctx.parent?.altMobileNumber.replace(/\D/g, "");

// if (formattedValueWithoutFormat === altMobileNumberWithoutFormat) {

//   if (ctx.parent?.format?.length !== ctx.parent?.formattedValue?.length) {
//     // matching the pattern
//     const length = (
//       ctx.parent?.format?.substring(ctx.parent?.format.indexOf(" ") + 1).match(/\./g) ||
//       []
//     ).length;
//     return ctx.createError({
//       message: t("_Mobile_number_must_be_of_length") + " " + length,
//     });
//   }
// }

//   let numberData = ctx.parent?.altMobileNumber?.replace(ctx?.parent?.dialCode, "");
//   if (numberData < 1) {
//     return ctx.createError({
//       path: `${ctx.path}`,
//       message: t("_Enter_valid_mobile_number"),
//     });
//   }
//   return true;
// });

const fromValidation = Yup.date()
  .max(new Date(), t("_From_value_should_be_less_than_current_date"))
  .required(t("_From_value_is_required"));

const emailValidation = Yup.string()
  // .email(t("_Must_be_valid_email"))
  // .min(2, t("_Must_be_at_least_2_characters"))
  .max(64, t("_Must_be_64_characters_or_less"))
  .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, {
    message: t("_Must_be_valid_email"),
  })
  .required(t("_Email_is_required"));

const workEmailValidation = Yup.string()
  // .email(t("_Must_be_valid_email"))
  // .min(2, t("_Must_be_at_least_2_characters"))
  .max(64, t("_Must_be_64_characters_or_less"))
  .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, {
    message: t("_Must_be_valid_work_email"),
  })
  .required(t("_Work_email_is_required"));

const emailValidationNoRequired = Yup.string()
  // .email(t("_Must_be_valid_email"))
  // .min(2, t("_Must_be_at_least_2_characters"))
  .max(64, t("_Must_be_64_characters_or_less"))
  .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, {
    message: t("_Must_be_valid_email"),
  });

const toValidation = Yup.date()
  .max(new Date(), t("_To_value_should_be_less_than_current_date"))
  .min(Yup.ref("from"), t("_Cannot_be_less_than_from_value"))
  .nullable();
//.required(t("_To_value_is_required"));

const hotJobfromValidation = Yup.date()
  .typeError("Invalid date format")
  .min(
    new Date(new Date().setMonth(new Date().getMonth() - 6)),
    "Start date cannot be more than 6 months in the past"
  )
  .nullable();

const hotJobToValidation = Yup.date()
  .typeError("Invalid date format")
  .required("End date is required")
  .nullable()
  .test(
    "is-after-start",
    "End date must be after or the same as the start date",
    function (value) {
      const { hot_job_from_date } = this.parent; // Access the start date value
      if (!hot_job_from_date || !value) return true; // Skip if either date is not set
      const startDate = new Date(hot_job_from_date).setHours(0, 0, 0, 0); // Strip time
      const endDate = new Date(value).setHours(0, 0, 0, 0); // Strip time
      return endDate >= startDate;
    }
  )
  .test(
    "is-within-6-months",
    "End date must be within 6 months of the start date",
    function (value) {
      const { hot_job_from_date } = this.parent; // Access the start date value
      if (!hot_job_from_date || !value) return true; // Skip if either date is not set
      const startDate = new Date(hot_job_from_date).setHours(0, 0, 0, 0); // Strip time
      const maxDate = new Date(hot_job_from_date);
      maxDate.setMonth(maxDate.getMonth() + 6); // Calculate 6 months from start date
      maxDate.setHours(0, 0, 0, 0); // Strip time
      const endDate = new Date(value).setHours(0, 0, 0, 0); // Strip time
      return endDate <= maxDate;
    }
  );

const titleValidation = Yup.string()
  .required(t("_Title_is_required"))
  .min(2, t("_Min_length_allowed_is_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"));

const educationQualificationValidation = Yup.string()
  .required(t("_Qualification_is_required"))
  .min(2, t("_Must_be_at_least_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"));

const educationUniversityValidation = Yup.string()
  .required(t("_University_/_School_is_required"))
  .min(2, t("_Must_be_at_least_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"))
  .matches(onlyNumSpaceNotAllowed, t("_Must_contain_atleast_one_Alphabet"));

const educationCollegeValidation = Yup.string()
  .required(t("_College_/_University_is_required"))
  .min(2, t("_Must_be_at_least_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"))
  .matches(onlyNumSpaceNotAllowed, t("_Must_contain_atleast_one_Alphabet"));

const educationSpecializationValidation = Yup.string()
  .nullable()
  .min(2, t("_Must_be_at_least_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"))
  //.matches(commonRegex, t("_Entered_special_character_is_not_allowed"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"));

const projectNameValidation = Yup.string()
  .required(t("_Project_name_is_required"))
  .matches(commonRegex, t("_Entered_special_character_is_not_allowed"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"))
  .min(2, t("_Must_be_at_least_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"));

const reponsibilityValidation = Yup.string()
  .min(2, t("_Must_be_at_least_2_characters"))
  .max(500, t("_Max_length_allowed_is_500_characters"))
  .matches(specialRegex, t("_Entered_special_character_is_not_allowed"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"));

const projectOutcomeValidation = Yup.string()
  .min(2, t("_Must_be_at_least_2_characters"))
  .matches(specialRegex, t("_Entered_special_character_is_not_allowed"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"))
  .required(t("_Project_outcome_is_required"));

const projectSkillValidation = Yup.array().min(1, t("_Skill_is_required"));

const graduationYearValidation = Yup.number()
  .required(t("_Graduation_year_is_required"))
  .integer(t("_Enter_valid_year"))
  .typeError(t("_Enter_valid_year"))
  .moreThan(1950, t("_Enter_valid_year"))
  .lessThan(
    new Date().getFullYear() + 1,
    t("_Graduation_year_should_be_less_than_or_equal_to_the_current_year")
  );

const careerBreakFromValidation = Yup.date().when("careerBreak", {
  is: "Yes",
  then: () =>
    Yup.date()
      .required(t("_Career_break_from_is_required"))
      .max(new Date(), t("_Career_break_from_value_should_be_less_than_current_date")),
  otherwise: () => Yup.date().notRequired(),
});

const careerBreakToValidation = Yup.date().when("careerBreak", {
  is: "Yes",
  then: () =>
    Yup.date()
      .required(t("_Career_break_to_is_required"))
      .max(new Date(), t("_Career_break_to_value_should_be_less_than_current_date"))
      .min(Yup.ref("careerBreakFrom"), t("_Cannot_be_less_than_career_break_from_value"))
      .test(
        "Difference",
        t("_Difference_should_be_minimum_of_1_month"),
        function (endDate, context) {
          const startDate = dayjs(context.parent.careerBreakFrom);

          if (dayjs(endDate).diff(startDate, "month") > 1) {
            return true;
          } else {
            return false;
          }
        }
      ),
  otherwise: () => Yup.date().notRequired(),
});

const reasonValidation = Yup.string().when("careerBreak", {
  is: "Yes",
  then: () =>
    Yup.string()
      .required(t("_Reason_is_required"))
      .min(2, t("_Min_length_allowed_is_2_characters"))
      .max(256, t("_Max_length_allowed_is_256_characters"))
      .matches(specialRegex, t("_Entered_special_character_is_not_allowed"))
      .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet")),
  otherwise: () => Yup.string().notRequired(),
});

const companyNameValidation = Yup.string()
  .required(t("_Company_Name_is_required"))
  .min(2, t("_Min_length_allowed_is_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"));

const descriptionValidation = Yup.string()
  .required(t("_Description_is_required"))
  .min(2, t("_Min_length_allowed_is_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"))
  .matches(commonRegex, t("_Entered_special_character_is_not_allowed"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"));

const designationValidation = Yup.string()
  .required(t("_Designation_is_required"))
  .min(2, t("_Min_length_allowed_is_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"))
  //.matches(commonRegex, t("_Entered_special_character_is_not_allowed"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"));

const projectDesignation = Yup.string()
  .min(2, t("_Min_length_allowed_is_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"))
  .matches(commonRegex, t("_Entered_special_character_is_not_allowed"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"))
  .required(t("_Project_designation_is_required"));

const cgpaValidation = Yup.string()
  .max(10, t("_The_length_of_CGPA_should_not_be_more_than_10_characters"))
  .matches(
    /^(?! )(?=.*[a-zA-Z0-9])[a-zA-Z0-9 !@#$%^&*()_+\-=,.<>?;:'"{}\[\]|\\\/%]*$/,
    t("Invalid CGPA")
  );

const currentLocationValidation = Yup.string()
  .min(2, t("_Min_length_allowed_is_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"))
  //.matches(commonRegex, t("_Entered_special_character_is_not_allowed"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"));

const certifiedInValidation = Yup.string()
  .required(t("_Required"))
  .min(2, t("_Must_be_at_least_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"))
  //.matches(commonRegex, t("_Entered_special_character_is_not_allowed"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"));

const certifiedYearValidation = Yup.date()
  .required(t("_Year_is_required"))
  .max(new Date(), t("_Year_must_be_in_the_past_or_current"));

const certifiedByValidation = Yup.string()
  .required(t("_Required"))
  .min(2, t("_Must_be_at_least_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"))
  //.matches(commonRegex, t("_Entered_special_character_is_not_allowed"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"));

const fromInternshipValidation = Yup.date()
  .max(new Date(), t("_From_value_should_be_less_than_current_date"))
  .required(t("_From_value_is_required"));

const toInternshipValidation = Yup.date()
  .nullable()
  .max(new Date(), t("_To_value_should_be_less_than_current_date"))
  .min(Yup.ref("from"), t("_Cannot_be_less_than_from_value"));
//.required(t("_To_value_is_required"));

const internshipAtValidation = Yup.string()
  .required(t("_Required"))
  .min(2, t("_Must_be_at_least_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"))
  //.matches(commonRegex, t("_Entered_special_character_is_not_allowed"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"));

const roleValidation = Yup.string()
  .min(2, t("_Must_be_at_least_2_characters"))
  .max(500, t("_Max_length_allowed_is_500_characters"))
  .matches(specialRegex, t("_Entered_special_character_is_not_allowed"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"));

const preferredcompanyValidation = Yup.string()
  .min(2, t("_Min_length_allowed_is_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"))
  //.matches(commonRegex, t("_Entered_special_character_is_not_allowed"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"));

const aboutYouValidation = Yup.string()
  .min(2, t("_Must_be_at_least_2_characters"))
  .max(5000, t("_Max_length_allowed_is_1024_characters"))
  .matches(specialRegex, t("_Entered_special_character_is_not_allowed"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"));

const hobbiesValidation = Yup.array().of(
  Yup.string()
    .min(2, t("_Min_length_allowed_is_2_characters"))
    .max(256, t("_Max_length_allowed_is_256_characters"))
    //.matches(commonRegex, t("_Entered_special_character_is_not_allowed"))
    .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"))
);

const spouseEmployedAtValidation = Yup.string()
  .min(2, t("_Must_be_at_least_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"));
//.matches(commonRegex, t("_Entered_special_character_is_not_allowed"));

const spouseCompanyValidation = Yup.string()
  .min(2, t("_Must_be_at_least_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"));

const statusRemarksValidation = Yup.string()
  .max(256, t("_Max_length_allowed_is_256_characters"))
  .min(2, t("_Min_length_allowed_is_2_characters"))
  .required(t("_Status_Remark_is_required"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"));

const selectedTechValidation = Yup.array().of(
  Yup.string()
    .required(t("_Technology_Field_is_Required"))
    .max(50, t("_Max_length_allowed_is_50_characters"))
    .min(2, t("_Min_length_allowed_is_2_characters"))
  //.matches(commonRegex, t("_Entered_special_character_is_not_allowed"))
);

const socialMedialinksValidation = Yup.array().of(
  Yup.object().shape({
    link: Yup.string()
      .max(256, t("_Max_length_allowed_is_256_characters"))
      .min(2, t("_Min_length_allowed_is_2_characters"))
      .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"))
      .matches(specialRegex, t("_Entered_special_character_is_not_allowed"))
      .matches(/^\S*$/, t("_Space_is_not_allowed")),
  })
);

const minMaxValidation = Yup.string()
  .required(t("_Required"))
  .min(2, t("_Must_be_at_least_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"));

const companyWebsiteValidation = Yup.string()
  .required(t("_Company_website_is_required"))
  .min(2, t("_Must_be_at_least_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"))
  .test("is-valid-website", t("_Invalid_company_website"), (value) => {
    return websiteRegex.test(value);
    //&&
    //(value.startsWith("http://") || value.startsWith("https://")) //to ensure if website url must contains http:// or https://
  });

const commonLengthValidation = Yup.string()
  .min(2, t("_Min_length_allowed_is_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"));

const preferredCompaniesValidation = Yup.array()
  .of(
    Yup.string()
      .min(2, t("_Must_be_at_least_2_characters"))
      .max(256, t("_Max_length_allowed_is_256_characters"))
      .matches(/^[a-zA-Z0-9\s]+$/, t("_Special_characters_not_allowed"))
      .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/, t("_Numbers_not_allowed"))
    //.matches(commonRegex, t("_Entered_special_character_is_not_allowed"))
  )
  .test("maxCompanies", t("_Max_of_5_companies_is_allowed"), (values) => {
    return values.length <= 5;
  });

const referrerValidation = Yup.array().of(
  Yup.object().shape({
    referrerName: Yup.string()
      .min(2, t("_Must_be_at_least_2_characters"))
      .max(256, t("_Max_length_allowed_is_256_characters"))
      //.matches(commonRegex, t("_Entered_special_character_is_not_allowed"))
      .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet")),
    // .test("referrerName-required", t("_Required"), function (value) {
    //   return !this.parent.emailId || !!value;
    // }),
    emailId: Yup.string()
      .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, {
        message: t("_Must_be_valid_email"),
      })
      .min(2, t("_Must_be_at_least_2_characters"))
      .max(64, t("_Must_be_64_characters_or_less"))
      .test("emailId-required", t("_Email_is_required"), function (value) {
        console.log("email:", value);
        return !this.parent.referrerName || !!value;
      }),
    // phoneNumber: Yup.string().test((value, ctx) => {
    //   console.log("value, ctx", value, ctx);
    //   if (ctx.parent?.phoneNumber?.replace(ctx?.parent?.dialCode, "")[0] === "0") {
    //     return ctx.createError({ message: t("_Mobile_number_cannot_start_with_0") });
    //   }

    //   if (
    //     ctx.parent?.phoneNumber?.replace(ctx?.parent?.dialCode, "") === "" ||
    //     value === ""
    //   ) {
    //     return ctx.createError({ message: t("_Mobile_number_is_required") });
    //   }
    //   const formattedValueWithoutFormat = ctx.parent?.formattedValue?.replace(/\D/g, "");
    //   const mobileNumberWithoutFormat = ctx.parent?.mobileNumber?.replace(/\D/g, "");

    //   if (formattedValueWithoutFormat === mobileNumberWithoutFormat) {
    //     if (ctx.parent?.format?.length !== ctx.parent?.formattedValue?.length) {
    //       // matching the pattern
    //       const length = (
    //         ctx.parent?.format
    //           ?.substring(ctx.parent?.format.indexOf(" ") + 1)
    //           .match(/\./g) || []
    //       ).length;
    //       return ctx.createError({
    //         message: t("_Mobile_number_must_be_of_length") + " " + length,
    //       });
    //     }
    //   }
    // }),

    phoneNumber: Yup.string().test(
      "phone-number-validation",
      t("_Mobile_Number_is_required"),
      function (value, ctx) {
        console.log("value, ctx", value, ctx);
        return !this.parent.referrerName || !!value;
      }
    ),
    // phoneNumber: mobileNumberValidation,

    relationShip: Yup.string().test(
      "relationShip-required",
      t("_RelationShip_is_required"),
      function (value) {
        return !this.parent.referrerName || !!value;
      }
    ),
  })
);

const preferredIndustryValidation = Yup.array().max(
  5,
  t("_Max_of_5_industries_is_allowed")
);

const workExperienceToValidation = Yup.boolean().when("currentlyWorkHere", {
  is: false,
  then: () =>
    Yup.date()
      .nullable()
      .required(t("_To_value_is_required"))
      .max(new Date(), t("_To_value_should_be_less_than_current_date"))
      .min(Yup.ref("from"), t("_Cannot_be_less_than_from_value")),
  otherwise: () =>
    Yup.date()
      .notRequired()
      .nullable()
      .max(new Date(), t("_To_value_should_be_less_than_current_date"))
      .min(Yup.ref("from"), t("_Cannot_be_less_than_from_value")),
});

const headingValidation = Yup.string()
  .max(256, t("_Max_length_allowed_is_256_characters"))
  .min(2, t("_Min_length_allowed_is_2_characters"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"));

const projectRoleValidation = Yup.number().required("Role is required");
const firstNameValidation = Yup.string()
  .max(50, t("_Max_length_allowed_is_50"))
  //allow english as well as latin characters
  .matches(onlyNumSpaceNotAllowed, t("_Enter_atleast_one_alphabet"))
  // .matches(/^[ A-Za-z0-9]*$/, t("_Special_characters_are_not_allowed"))
  .required(t("_First_Name_is_required"));

const lastNameValidation = Yup.string()
  .max(50, t("_Max_length_allowed_is_50"))
  //allow english as well as latin characters
  .matches(onlyNumSpaceNotAllowed, t("_Enter_atleast_one_alphabet"))
  .required(t("_Last_Name_is_required"));

const companyName = Yup.string()
  .max(256, t("_Max_length_allowed_is_256_characters"))
  .min(2, t("_Min_length_allowed_is_2_characters"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"))
  .required(t("_Company_Name_is_required"));

const linkValidation = Yup.string()
  .max(256, t("_Max_length_allowed_is_256_characters"))
  .min(2, t("_Min_length_allowed_is_2_characters"))
  .matches(/^\S*$/, t("_Space_is_not_allowed"))
  .matches(linkedinRegex, t("_Invalid_company_LinkedIn_URL"))
  .required(t("_Company_linkedin_is_required"));

const companyDescriptionValidation = Yup.string()
  .min(2, t("_Must_be_at_least_2_characters"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"))
  .required(t("_Company_description_is_required"));
const companyDescriptionNotRequiredValidation = Yup.string()
  .min(2, t("_Must_be_at_least_2_characters"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"));

const careerSummaryValidation = Yup.string()
  .required(t("_Career_Summary_is_Required"))
  .min(2, t("_Must_be_at_least_2_characters"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"));

const jobSummaryValidation = Yup.string()
  .required(t("_Job_Summary_is_Required"))
  .min(2, t("_Must_be_at_least_2_characters"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"));

const companyAddressValidation = Yup.string()
  .max(256, t("_Max_length_allowed_is_256_characters"))
  .min(2, t("_Min_length_allowed_is_2_characters"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"))
  .required(t("_Company_address_is_required"));

const pincodeValidation = Yup.string()
  .required(t("_PIN/ZIP_code_is_required"))
  // .matches(/^(?=.*[0-9A-Za-z]).{5,10}$/, 'Invalid PIN/ZIP code')
  // .matches(/^(?![A-Za-z]+$)[0-9A-Za-z\s-]*$/, 'Invalid PIN/ZIP code')
  .matches(invalidPINRegex, t("_Invalid_PIN_/_ZIP_code"))
  .matches(onlyZerosRegex, t("_Only_zeros_not_allowed"))
  .min(5, t("_Min_length_allowed_is_5_characters"))
  .max(10, t("_Max_length_allowed_is_10_characters"));

const industryValidation = Yup.string()
  .max(256, t("_Max_length_allowed_is_256_characters"))
  .min(2, t("_Min_length_allowed_is_2_characters"))
  //.matches(commonRegex, t("_Entered_special_character_is_not_allowed"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"));

const passwordValidation = Yup.string()
  .min(8, t("_Min_allowed_characters_are_8"))
  .max(32, t("_Max_length_allowed_is_32"))
  .matches(/[A-Z]/, t("_Must_contain_at_least_one_uppercase_letter"))
  .matches(/[a-z]/, t("_Must_contain_at_least_one_lowercase_letter"))
  .matches(/[0-9]/, t("_Must_contain_at_least_one_number"))
  .matches(
    /[!@#\$%^*()_+{}\[\]:;<>,.?~|]/,
    t("_Must_contain_at_least_one_special_character")
  )
  .matches(passwordRestrictedRegex, t("_Allowed_only") + passwordAllowedCharacters);

const commentsValidation = Yup.string()
  .required(t("_Comments_is_required"))
  .min(2, t("_Min_length_allowed_is_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"))
  .matches(onlyNumSpaceNotAllowed, t("_Must_contain_atleast_one_Alphabet"));

const jobLocationValidation = Yup.array().of(
  Yup.object().shape({
    states: Yup.array().test("required", t("_Job_location_is_required"), (value, ctx) => {
      let statesn = State.getStatesOfCountry(ctx.parent.country);
      console.log("jobLocationValidation", value, ctx, statesn);

      if (statesn.length > 0 && value.length < 1) {
        return false;
      } else {
        return true;
      }
    }),
    //min(1, "Job location is required"),
  })
);

const jobTitleValidation = Yup.string()
  .required(t("_Job_title_is_required"))
  .min(2, t("_Min_length_allowed_is_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"))

  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"));

const employmentTypeValidation = Yup.string().required(t("_Employment_type_is_required"));
const workPlaceTypeValidation = Yup.string().required(t("_Workplace_type_is_required"));
const validTillDateValidation = Yup.date()
  .required(t("_Valid_till_date_is_required"))
  .typeError(t("_Invalid_date"))
  .min(new Date(), ({ originalValue }) => {
    // console.log("originalValue", originalValue);
    return originalValue <= new Date()
      ? t("_Date_should_not_be_less_than_current_date")
      : undefined;
  })
  .max(dayjs(new Date()).add(30, "d").toDate(), ({ originalValue }) => {
    //console.log("originalValue", originalValue);
    return originalValue > dayjs(new Date()).add(30, "d").toDate()
      ? t("_Maximum_date_should_not_be_greater_than_30_days_from_current_date")
      : undefined;
  });

const minExperienceValidation = Yup.string().required(
  t("_Minimum_experience_is_required")
);
const maxExperienceValidation = Yup.string()
  .required(t("_Maximum_experience_is_required"))
  .test(
    "max-eperience",
    t("_Please_select_value_more_than_minimum_experience"),
    (value, ctx) => {
      return parseInt(ctx?.parent?.minExperience) < parseInt(value);
    }
  );
const qualificationValidation = Yup.array().required(t("_Qualification_is_required"));
const noticePeriodValidation = Yup.string().required(t("_Notice_period_is_required"));
const jobDescriptionValidation = Yup.string().required(t("_Job_description_is_required"));
const minimumCTCValidation = Yup.string()
  .required(t("_Minimum_CTC_is_required"))
  .matches(/^[0-9]+$/, t("_Only_numeric_values_allowed"))
  .min(0, t("_Please_enter_value_greater_than_0"))
  .max(9999999999, t("_Enter_value_less_than_9999999999"))
  .test({
    name: "lessThanMaximumCTC",
    message: t("_Enter_value_less_than_maximum_ctc"),
    test: function (value) {
      const { maximumCTC } = this.parent;
      // Only perform the check if both minimumCTC and maximumCTC are numeric
      if (/^[0-9]+$/.test(value) && /^[0-9]+$/.test(maximumCTC)) {
        return Number(value) <= Number(maximumCTC);
      }
      return true; // Skip the check if non-numeric values are present
    },
  });

const maximumCTCValidation = Yup.string()
  .required(t("_Maximum_CTC_is_required"))
  .matches(/^[0-9]+$/, t("_Only_numeric_values_allowed"))
  .min(0, t("_Please_enter_value_greater_than_0"))
  .max(9999999999, t("_Enter_value_less_than_9999999999"));

const roleNameValidation = Yup.string()
  .required(t("_Role_Name_is_required"))
  .min(3, t("_Min_length_allowed_is_3_characters"))
  .max(32, t("_Max_length_allowed_is_32_characters"))
  .matches(/^[a-zA-Z0-9]+[\w:\_\s]+$/, t("_Only_alphanumeric_and_underscores_allowed"))
  .matches(/^(?!.*\s{2,}).*$/, t("_No_more_than_two_spaces_allowed"))
  .matches(/^(?!\d+$).+$/, t("_Enter_atleast_one_alphabet"))
  .matches(/^[a-zA-Z][\s\S]*$/, t("_Enter_an_alphabet_at_the_start"))
  .matches(/^[^\s].*$/, t("_No_space_at_the_start"));

const userFirstNameValidation = Yup.string()
  .required("First name is required")
  .min(1, "First name must be at least 1 character long")
  .max(50, "First name cannot be longer than 50 characters")
  .matches(/^[a-zA-Z0-9]+[\w:\_\s]+$/, t("_Enter_atleast_one_alphabet"))
  .matches(/^(?!.*\s{2,}).*$/, t("_No_more_than_two_spaces_allowed"))
  .matches(/^(?!\d+$).+$/, t("_Enter_atleast_one_alphabet"))
  .matches(/^[a-zA-Z][\s\S]*$/, t("_Enter_an_alphabet_at_the_start"));

const userLastNameValidation = Yup.string()
  .required("Last name is required")
  .min(1, "Last name must be at least 1 character long")
  .max(50, "Last name cannot be longer than 50 characters")
  .matches(/^[a-zA-Z0-9]+[\w:\_\s]+$/, t("_Enter_atleast_one_alphabet"))
  .matches(/^(?!.*\s{2,}).*$/, t("_No_more_than_two_spaces_allowed"))
  .matches(/^(?!\d+$).+$/, t("_Enter_atleast_one_alphabet"))
  .matches(/^[a-zA-Z][\s\S]*$/, t("_Enter_an_alphabet_at_the_start"));

const userCountryNameValidation = Yup.string()
  .min(2, "Country name must be at least 2 characters long")
  .max(250, "Country name cannot be longer than 250 characters")
  .matches(
    /^[a-zA-Z\s]*$/,
    "Only alphabets characters and space are allowed in country name"
  );

const userEmailValidation = Yup.string()
  .required("Email is required")
  .email("Invalid email address")
  .max(64, "Email cannot be longer than 64 characters");
// .test("custom-email", "Invalid email address");

const userMobileValidation = Yup.string()
  .required("Mobile number is required")
  .min(8, "Mobile number must be at least 8 digits long")
  .max(15, "Mobile number cannot be longer than 15 digits")
  .matches(
    /^[0-9]{8,15}$/,
    "Phone number must have a minimum of 8 to a maximum of 15 digits and only numbers are allowed"
  );

const rateYourSkillValidation = Yup.object({
  secondarySkills: Yup.array().of(
    Yup.object().shape({
      sskill: Yup.string().required(t("_Skill_is_required")),
      sweightage: Yup.number().required(t("_Level_is_required")),
    })
  ),
});

const assessmentNameValidation = Yup.string()
  .required(t("_Assessment_Name_is_required"))
  .min(2, t("_Min_length_allowed_is_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"));

const assessmentSkillValidation = Yup.array().min(1, t("_Skills_is_required"));

const assessmentNoOfQuestionValidation = Yup.number().required(
  t("_No_of_questions_is_required")
);

const assessmentCategoryValidation = Yup.array().of(
  Yup.string().required(t("_Category_is_required"))
);
const assessmentTypeValidation = Yup.array().of(
  Yup.string().required(t("_Type_is_required"))
);
const assessmentLevelValidation = Yup.string().required(t("_Level_is_required"));
const assessmentTimeLimitValidation = Yup.number().required(
  t("_Time_limit_(in_mins)_is_required")
);
const assessmentNoOfAttemptsValidation = Yup.number().required(
  t("_No_of_attempts_is_required")
);

const assessmentDescriptionValidation = Yup.string().required(
  t("_Description_is_required")
);

const assessmentchargeableValidation = Yup.string().required(
  t("_Chargeable_is_required")
);

const assessmentCostValidation = Yup.string()
  .min(1, t("_Min_Rs_1_is_required"))
  .when("chargeable", {
    is: (chargeable) => chargeable === true,
    then: () => Yup.number().required(t("_Cost_(in_RS)_is_required")),
  });

const assessmentFileValidation = Yup.mixed().when("customQuestion", {
  is: (customQuestion) => customQuestion === true,
  then: () => Yup.number().required(t("_Assessment_File_is_required")),
});

const customQuestionValidation = Yup.string().required(t("_Custom_question_is_required"));

const assessmentCandidateEmailIdValidation = Yup.string()

  .max(64, t("_Must_be_64_characters_or_less"))
  .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, {
    message: t("_Must_be_valid_email"),
  })
  .required(t("_Candidate_email_is_required"));

const assessmentPointsValidation = Yup.string().required(t("_Points_is_required"));

const discountDescriptionValidation = Yup.string()
  .min(2, t("_Min_length_allowed_is_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"))
  .matches(commonRegex, t("_Entered_special_character_is_not_allowed"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"))
  .required(t("_Discount_description_is_required"));

const eventTitleValidation = Yup.string()
  .required(t("_Event_Title_is_required"))
  .min(2, t("_Min_length_allowed_is_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"));

const eventDateValidation = Yup.date().required(t("_Event_date_is_required"));

const publishTillValidation = Yup.date()
  .required(t("_Publish_till_date_is_required"))
  .when("eventDate", (eventDate, schema) =>
    eventDate
      ? schema.min(
          dayjs(eventDate).toDate(),
          t("_Publish_till_date_cannot_be_earlier_than_the_event_date")
        )
      : schema
  );

const eventCompanyNameValidation = Yup.array().min(1, t("_Company_is_required"));

const eventDescriptionValidation = Yup.string()
  .min(2, t("_Min_length_allowed_is_2_characters"))
  .max(256, t("_Max_length_allowed_is_256_characters"))
  .matches(commonRegex, t("_Entered_special_character_is_not_allowed"))
  .matches(onlySpaceRegex, t("_Must_contain_atleast_one_Alphabet"))
  .required(t("_Event_description_is_required"));

const eventImageValidation = Yup.mixed().required(t("_Event_File_is_required"));
const eventCategoryValidation = Yup.string().required(t("_Event_category_is_required"));

export {
  jobTitleValidation,
  jobDescriptionValidation,
  maximumCTCValidation,
  minimumCTCValidation,
  noticePeriodValidation,
  qualificationValidation,
  maxExperienceValidation,
  minExperienceValidation,
  validTillDateValidation,
  workPlaceTypeValidation,
  employmentTypeValidation,
  jobLocationValidation,
  mobileNumberValidation,
  fromValidation,
  toValidation,
  educationQualificationValidation,
  educationUniversityValidation,
  educationSpecializationValidation,
  projectNameValidation,
  reponsibilityValidation,
  graduationYearValidation,
  careerBreakFromValidation,
  careerBreakToValidation,
  reasonValidation,
  companyNameValidation,
  designationValidation,
  projectDesignation,
  cgpaValidation,
  currentLocationValidation,
  certifiedInValidation,
  certifiedYearValidation,
  certifiedByValidation,
  fromInternshipValidation,
  toInternshipValidation,
  internshipAtValidation,
  roleValidation,
  preferredcompanyValidation,
  aboutYouValidation,
  hobbiesValidation,
  spouseEmployedAtValidation,
  selectedTechValidation,
  socialMedialinksValidation,
  commonLengthValidation,
  preferredCompaniesValidation,
  referrerValidation,
  spouseCompanyValidation,
  projectRoleValidation,
  preferredIndustryValidation,
  workExperienceToValidation,
  headingValidation,
  firstNameValidation,
  lastNameValidation,
  companyName,
  linkValidation,
  companyDescriptionValidation,
  industryValidation,
  emailValidation,
  minMaxValidation,
  companyAddressValidation,
  companyWebsiteValidation,
  descriptionValidation,
  titleValidation,
  educationCollegeValidation,
  careerSummaryValidation,
  jobSummaryValidation,
  passwordValidation,
  pincodeValidation,
  emailValidationNoRequired,
  altMobileNumberValidation,
  statusRemarksValidation,
  commentsValidation,
  projectOutcomeValidation,
  projectSkillValidation,
  companyDescriptionNotRequiredValidation,
  altMobileNumberValidationRequired,
  workEmailValidation,
  roleNameValidation,
  userFirstNameValidation,
  userCountryNameValidation,
  userEmailValidation,
  userLastNameValidation,
  userMobileValidation,
  rateYourSkillValidation,
  assessmentNameValidation,
  assessmentSkillValidation,
  assessmentNoOfQuestionValidation,
  assessmentCategoryValidation,
  assessmentTypeValidation,
  assessmentLevelValidation,
  assessmentTimeLimitValidation,
  assessmentNoOfAttemptsValidation,
  assessmentDescriptionValidation,
  assessmentchargeableValidation,
  assessmentCostValidation,
  assessmentFileValidation,
  customQuestionValidation,
  assessmentCandidateEmailIdValidation,
  assessmentPointsValidation,
  discountDescriptionValidation,
  hotJobfromValidation,
  hotJobToValidation,
  eventTitleValidation,
  eventDateValidation,
  publishTillValidation,
  eventDescriptionValidation,
  eventCompanyNameValidation,
  eventImageValidation,
  eventCategoryValidation,
};
