import React, { useState, useContext } from "react";
import { LocationIcon, WorkIcon } from "../../icons";
import SaveButton from "../../components/Button/OutlinedButton";
import ApplyButton from "../../components/Button/Button";
import Assessment from "../Dialog/Assessment";
import dayjs from "dayjs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { applyJob, saveJob, getJobDetail } from "../../lib/jobs-api";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context/AuthContext";
import ErrorDialog from "../Dialog/ErrorDialog";
import { getProfileDetails } from "../../lib/userprofile-api";
import SuccessDialog from "../Dialog/SuccessDialog";
import ApplyConfirmation from "../Dialog/ApplyConfirmation";
import { useNavigate } from "react-router-dom";
import { calculatePostedDays } from "../../common/common";
import { jobStatus, templates } from "../../common/constants";

var relativeTime = require("dayjs/plugin/relativeTime");
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

function JobBlockDetails({
  company,
  key,
  onClickDetail,
  isContainerShrunk,
  setSelectedJob,
}) {
  const { t } = useTranslation();
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const { userId, template } = useContext(AuthContext);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [subContent, setSubContent] = useState("");
  // const [mark, setMark] = useState(1);
  const [pic, setPic] = useState("");
  const [video, setVideo] = useState("");
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [successDialog, setSuccessDialog] = useState(false);
  const queryClient = useQueryClient();
  const { mutate: applyJobApi } = useMutation(applyJob);
  const { mutate: saveJobApi } = useMutation(saveJob);
  const handleDetailClick = (company) => {
    onClickDetail && onClickDetail();
    setSelectedJob(company); // Pass the selected job details to the parent component
  };
  const { data, isLoading, refetch } = useQuery(
    [`JobDeatils/${company?.job_id}`, company?.job_id, userId],
    getJobDetail
  );
  // console.log("company", company);
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleProfile = () => {
    navigate("/app/profile/profile-information");
    setOpenDialog(false);
  };
  const handleErrorDialogOpen = () => {
    setErrorDialogOpen(true);
  };

  const handleErrorDialogClose = () => {
    setErrorDialogOpen(false);
  };

  useQuery(["/profile Details", userId], getProfileDetails, {
    onSuccess: (response) => {
      const video = response?.data?.data?.profile_information?.intro_video_file;
      setVideo(video);
      const pic = response?.data?.data?.profile_information?.profile_pic_file?.length;
      setPic(pic);
    },
  });

  return (
    <div>
      {/* {console.log("companyName", company?.job_status)} */}
      <div
        className={` ${hoveredIndex === key ? "bg-blue-100" : ""}`}
        onMouseEnter={() => setHoveredIndex(key)}
        onMouseLeave={() => setHoveredIndex(-1)}
        onClick={() => handleDetailClick(company)} // Pass the job details as a parameter
      >
        <div className="p-4">
          <img
            src={company?.company_logo}
            alt="Company Logo"
            className="w-[50px] h-[50px] "
          />
          <div className="mt-1 break-words">
            <span className="text-title text-[15px] font-medium line-clamp-1 ">
              {company?.job_title}
            </span>
          </div>
          <div className="mt-2 whitespace-normal break-words leading-none">
            <span className="text-grayTextColor text-[12px] font-medium line-clamp-3">
              {company?.company_desc}
              {/* We are seeking an experienced and detail-oriented Quality Analyst to join our
          dynamic team. As a Quality Analyst, you will play a crucial role in ensuring the
          delivery of high-quality products and services to our customers */}
            </span>
          </div>
          <div className="flex item-center mt-3">
            <WorkIcon className="" />
            <span className="ml-2 mt-1 text-title text-[12px] font-medium">
              {/* {calculatePostedDays(company?.posted_date)} {t("_days_ago")} */}
              {(calculatePostedDays(company?.posted_date) < 30 )? <div> {calculatePostedDays(company?.posted_date)>1?calculatePostedDays(company?.posted_date) + " " + t("_days_ago"):calculatePostedDays(company?.posted_date)=== 0? t("_Today"):calculatePostedDays(company?.posted_date) + " " + t("_day_ago")}</div> : <div>{t("_30_days_ago")}</div>}

            </span>
            <LocationIcon className="ml-5 h-4 w-3 mt-1 " />
            <span className="ml-2 mt-1 text-title text-[12px] font-medium">
              {company?.city[0]}
            </span>
          </div>
          <div
            className={`flex ${
              data?.data?.job_status === jobStatus.saved ||
              data?.data?.job_status === jobStatus.applied ||
              data?.data?.job_status === jobStatus.saved_applied
                ? ""
                : "gap-3"
            } my-5`}
          >
            {template === templates.platformAdmin ||
            data?.data?.job_status === jobStatus.saved ||
            data?.data?.job_status === jobStatus.applied ||
            data?.data?.job_status === jobStatus.saved_applied ? null : (
              <SaveButton
                buttonClassName="w-[120px] h-[40px] md:max-w-[120px] text-xs"
                label={data?.data?.is_saved === jobStatus.saved ? "_UNSAVE" : "_SAVE"}
                onClick={() => {
                  saveJobApi(
                    {
                      userId,
                      job_id: company?.job_id,
                      mark: company?.is_saved === 1 ? 0 : 1,
                      score: company?.score,
                    },
                    {
                      onSuccess: (response) => {
                        setSuccessDialog(true);
                        queryClient.invalidateQueries("recommendedjobsList");
                        setMessage(response.data.message);
                        refetch();
                      },
                      onError: (error) => {
                        handleErrorDialogOpen();
                        setMessage(error?.response?.data?.message);
                      },
                    }
                  );
                }}
              />
            )}
            {template === templates.platformAdmin ||
            data?.data?.job_status === jobStatus.applied ? null : (
              <ApplyButton
                type="button"
                label={t("_APPLY")}
                buttonClassName="w-[120px] h-[40px] md:max-w-[120px] text-xs -mt-0 mb-0"
                onClick={() => {
                  // if (video !== null && pic !== 0) {
                  applyJobApi(
                    { userId, job_id: company.job_id, score: company?.score },
                    {
                      onSuccess: () => {
                        refetch();
                        if (video !== "" && pic !== 0) {
                          setSuccessDialog(true);
                        } else {
                          setOpenDialog(true);
                        }
                        //setMessage("_Applied_Successfully");
                        setMessage("_Job_Applied_Successfully.");
                        setSubContent(
                          "_Profiles_with_candidate_photo_and_video_have_excellent_chances_of_getting_selected_for_the_interview_process._We_recommend_you_to_upload_your_picture_and_complete_the_video_to_improve_your_chances_of_getting_a_call_back."
                        );
                        // handleDialogOpen();
                      },
                      onError: (error) => {
                        setErrorDialogOpen(true);
                        setMessage(error?.response?.data?.message);
                      },
                    }
                  );
                  // } else {
                  //   handleDialogOpen();
                  //   setMessage(
                  //     t(
                  //       "_Profiles_with_candidate_photo_and_video_have_excellent_chances_of_getting_selected_for_the_interview_process._We_recommend_you_to_upload_your_picture_and_complete_the_video_to_apply_for_this_job."
                  //     )
                  //   );
                  // }
                }}
              ></ApplyButton>
            )}
          </div>
        </div>
        {/* <Assessment open={openDialog} handleClose={handleDialogClose} /> */}
        <ApplyConfirmation
          message={message}
          open={openDialog}
          handleProfile={handleProfile}
          subContent={subContent}
          onClose={() => setOpenDialog(false)}
        />
        <ErrorDialog
          open={errorDialogOpen}
          message={message}
          onClick={handleErrorDialogClose}
        />
        <SuccessDialog
          message={message}
          open={successDialog}
          onOkayClick={() => setSuccessDialog(false)}
        />
      </div>
    </div>
  );
}

export default JobBlockDetails;
