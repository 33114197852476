import { axiosPrivate } from "../common/axiosPrivate";
import getAPIMap from "../routes/ApiUrls";

/**
 * This method is used for listing categories
 * @param {*} options
 * @returns
 */
export async function getCategoryList() {
  let url = getAPIMap("getCategory");
  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used for submitting the ticket
 * @param {*} options
 * @returns
 */
export async function submitTicket(options) {
  let url = getAPIMap("submitTicket");

  if (options.isSurvey) {
    url += "?survey=1";
  }
  let response = axiosPrivate.post(url, options.reqData);
  return response;
}

/**
 * This method is used for listing user tickets
 * @param {*} options
 * @returns
 */
export async function getUserTickets({ queryKey }) {
  let url = getAPIMap("getUserTickets");
  url = url.replace("{userid}", queryKey[1]);
  url = url.concat(`?page=${queryKey[2]}`);
  url = url.concat(`&pagesize=${queryKey[3]}`);
  if (queryKey[5] && queryKey[5].length > 0)
    url = url.concat(`&category_id=${queryKey[5]}`);
  if (queryKey[6] && queryKey[6].length > 0) url = url.concat(`&status=${queryKey[6]}`);
  if (queryKey[4] && queryKey[4].length > 0 && queryKey[4][0] !== "All")
    url = url.concat(`&days=${queryKey[4]}`);
  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used for list all the tickets (Admin)
 * @param {*} options
 * @returns
 */
export async function getAllTicketList(options) {
  let url = getAPIMap("getAllTicket");
  url = `${url}?page=${options.queryKey[1]}&pagesize=${options.queryKey[2]}`;
  if (
    options.queryKey[3] &&
    options.queryKey[3].length > 0 &&
    options.queryKey[3][0] !== "All"
  )
    url = url.concat(`&days=${options.queryKey[3]}`);
  if (options.queryKey[4] && options.queryKey[4].length > 0)
    url = url.concat(`&status=${options.queryKey[4]}`);
  if (options.queryKey[5] && options.queryKey[5].length > 0)
    url = url.concat(`&company_name=${options.queryKey[5]}`);

  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used for view sales ticket By Id
 * @param {*} options
 * @returns
 */
export async function getSalesTicketDetailsById(options) {
  let url = getAPIMap("getDetailsByTicketId");
  url = url.replace("{ticketid}", options.queryKey[1]);
  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used for edit sales ticket By Id
 * @param {*} options
 * @returns
 */
export async function updateSalesTicket(options) {
  let url = getAPIMap("editDetails");
  url = url.replace("{ticketid}", options.ticketId);
  let response = axiosPrivate.put(url, options.payload);
  return response;
}

/**
 * This method is used for list company names in filter for Sales Ticket (Admin)
 */
export async function getCompanyList() {
  let url = getAPIMap("getCompanyList");
  let response = axiosPrivate.get(url);
  return response;
}